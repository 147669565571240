import React from "react"

import { Container, SEOPage } from "../components";
import { Breadcrumb } from "site/src/components";
import { Grid2, Grid213, StyledPage } from "site/src/components/css";
import { FaQuoteLeft } from "react-icons/fa";

function ProfileProperties() {
  const SEO = {
    entity: "page",
    title: "Properties - Company profile",
    keywords: `company profile, properties`,
    description: `Barrow Construction (Pty) Ltd was established in 1897. The company began as a small construction company in Johannesburg, building residential houses in the early 20th century.`,
    url: `/construction-company-profile`
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div>
        <Breadcrumb show={true} data={{ type: "content", title: SEO.title, image: "https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_page_headers-properties_desktop_wbqonu.jpg" }} />
        <StyledPage>

          <Grid213>
            <div>
              <div>
                <h5 style={{ fontWeight: 300, textTransform: "uppercase", textAlign: "right", background: "#163656", margin: 0, padding: "2rem", color: "white" }}>Properties</h5>
                <img alt="properties" src="https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_Main_banner_properties_desktop_ra0rl4.jpg" style={{ maxWidth: "100%" }} />
              </div>
            </div>
            <div>
              <h1 style={{ margin: 0, fontWeight: 300 }}>Company</h1>
              <h1 style={{ margin: 0, fontWeight: 300, color: "#163656" }}>Profile</h1>
            </div>
            <div>

            </div>
          </Grid213>
          <div style={{ marginTop: "3rem" }}>
            <Grid2>
              <div>
                <p>Barrow Properties is the property development and investment arm of the Barrow Group.</p>
                <p>Property development was a natural and obvious extension to Barrow Construction and the first projects were undertaken in the 1980’s. This coincided with the move of businesses out of the Johannesburg CBD and resulted in the successful development of office parks such as Woodmead Office Park, Bedfordview Office Park, Thornhill Office Park (Midrand) and Pinewood Office Park (Woodmead).</p>
                <p>Since then the business has grown in size but the focus has remained largely on AAA Grade office buildings in prime locations around Johannesburg.</p>
                <p>Notable developments have included 1 and 3 Melrose Boulevard (Melrose Arch), Katherine & West (Sandton), Oxford & Glenhove Precinct (Rosebank) and Park Lane West (Menlyn Maine).</p>
                <p>As the property portfolio has grown so has the asset and property management side of the business evolved. We take great pride in the relationships that we have developed with our tenants and strive to work closely with them to ensure that their individual requirements are met.</p>
                <p>We continue to look for new opportunities to develop and improve on our existing portfolio, thereby enhancing value and making office environments more appealing for tenants. Aligned to this is the need for our buildings to be cutting edge in terms design and to be able offer the best in amenities to the occupants.</p>
              </div>
              <div>
                <p>Equally we seek development opportunities in prime locations, especially where the development forms part of a planned environment and is of appeal to businesses.</p>
                <p>Our close relationship with Barrow Construction and our professional teams brings great synergy to the business and ensures that all parties are equally aligned in their thinking. This also positions us well to undertake turnkey developments for clients or to undertake joint ventures with development partners. We are fortunate to have been involved in many joint ventures over the years and this has resulted in long lasting relationships in the industry.</p>
                <p>Aside from offices, we have also been involved in the development of residential apartments and these have included Metropolis on Park (Sandton), Ipanema (Houghton) and Birchwood Village (Broadacres).</p>
                <p>We are committed to evolving in this ever-changing landscape and strive to offer the best solutions to our business partners and clients.</p>
              </div>
            </Grid2>
          </div>

        </StyledPage>
      </div>
    </Container>
  )
}

export default ProfileProperties